<template>
    <b-modal
      v-model="data.showModal"
      button-size="sm"
      modal-class="burntest-modal"
      footer-class="w-100"
      no-fade
      @show="onShow"
      @hide="onClose"
      @close="onClose"
      size="xl"
      hide-header
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <h1 class="flex gap-2 items-center text-2xl">
          <div class="font-bold">File</div>
          <div v-if="record" class="flex gap-2">
            <div>-</div>
            <div>{{ getFirstLine(record.sample_note) }}</div>
          </div>
        </h1>
      </div>

      <!-- Modal content -->
      <div v-if="record" class="flex flex-column gap-4">
        <div class="grid grid-cols-3 gap-4">
          <table class="table table-sm">
            <tr>
              <td>Date</td>
              <td>
                <date-picker
                  class="date-picker w-full"
                  v-model="record.sample_date"
                  format="DD.MM.YYYY"
                  type="date"
                  valueType="YYYY-MM-DD"
                  :clearable="false"
                  :lang="datepickerLocale"
                />
              </td>
            </tr>

            <tr>
              <td>Fragrance</td>
              <td>
                <div class="flex gap-2">
                  <v-select
                    v-model="record.fragrance_id"
                    :options="data.options.fragrance"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    class="flex-1"
                  />

                  <v-select
                    v-model="record.fragrance_pct_id"
                    :options="data.options.fragrance_pct"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    style="width: 100px;"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td class="text-nowrap">Sample no.</td>
              <td>
                <b-form-input
                  v-model="record.sample_number"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td class="text-nowrap">Cold Throw</td>
              <td>
                <button-select
                  v-model="record.cold_throw"
                  :options="data.options.cold_throw"
                  size="sm"
                  clearable
                />
              </td>
            </tr>

            <tr>
              <td>Wick</td>
              <td>
                <v-select
                  v-model="record.wick_id"
                  :options="data.options.wick"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                />
              </td>
            </tr>

            <tr>
              <td>Flame</td>
              <td>
                <button-select
                  v-model="record.flame"
                  :options="data.options.flame"
                  size="sm"
                  clearable
                />
              </td>
            </tr>

            <tr>
              <td class="text-nowrap">Melt Pool (hours):</td>
              <td>
                <button-select
                  v-model="record.melt_pool"
                  :options="data.options.melt_pool"
                  size="sm"
                  clearable
                />
              </td>
            </tr>

            <tr>
              <td class="text-nowrap">Hot Throw</td>
              <td>
                <button-select
                  v-model="record.hot_throw"
                  :options="data.options.hot_throw"
                  size="sm"
                  clearable
                />
              </td>
            </tr>

            <tr>
              <td>Smoke</td>
              <td>
                <button-select
                  v-model="record.smoke"
                  :options="data.options.smoke"
                  size="sm"
                  clearable
                />
              </td>
            </tr>

            <tr>
              <td class="text-nowrap">Jar temp.</td>
              <td>
                <button-select
                  v-model="record.jar_temp"
                  :options="data.options.jar_temp"
                  size="sm"
                  clearable
                />
              </td>
            </tr>

            <tr>
              <td class="row-fit">Result</td>
              <td>
                <button-select
                  v-model="record.sample_result"
                  :options="data.options.sample_result"
                  size="sm"
                  clearable
                />
              </td>
            </tr>

            <tr>
              <td>Notes</td>
              <td>
                <b-form-textarea
                  v-model="record.sample_note"
                  rows="4"
                  max-rows="6"
                  trim
                ></b-form-textarea>
              </td>
            </tr>
          </table>
          <div class="col-span-2 flex flex-col gap-4">
            <div class="grid grid-cols-4 gap-4">
              <div>
                <div>Before Burn Front</div>
                <image-box :data="record" field="preburn_front_file_id" />
              </div>

              <div>
                <div>Before Burn Top</div>
                <image-box :data="record" field="preburn_top_file_id" />
              </div>

              <div>
                <div>After Burn Front</div>
                <image-box :data="record" field="postburn_front_file_id" />
              </div>

              <div>
                <div>After Burn Top</div>
                <image-box :data="record" field="postburn_top_file_id" />
              </div>
            </div>
            <div>
              <video-box :data="record" field="video_file_id" />
            </div>
          </div>
        </div>

        <h3 class="text-lg">Gallery</h3>
        <gallery
          :files="sortedFiles"
          :busy="uploadingCount > 0"
          @upload="uploadFile"
          @delete="deleteFile"
        />
      </div>
      <div v-else-if="isLoading">
        Loading..
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-100 flex justify-between gap-4 items-center">
        <div>
          <b-btn
            variant="white"
            size="sm"
            @click="onClose()"
            :disabled="isLoading"
          >
            Close
          </b-btn>
        </div>

        <div v-if="savingCount > 0 || uploadingCount > 0" class="flex gap-2 items-center">
          <b-spinner label="Spinning" small></b-spinner>
          <span>Saving..</span>
        </div>
        <div v-else>
          Changes are saved automatically.
        </div>

        <div class="flex gap-2">
          <b-btn
            variant="primary"
            size="sm"
            @click="downloadFiles()"
          >
            Download
          </b-btn>

          <b-btn
            variant="danger"
            size="sm"
            @click="deleteRecord()"
          >
            Delete
          </b-btn>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import {
  datepickerLocale,
  downloadFile,
  getFirstLine,
} from '@/helpers';

const _ = require('lodash');

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const Gallery = () => import('@/components/global/gallery/Gallery.vue');
const ImageBox = () => import('@/components/global/ImageBox.vue');
const VideoBox = () => import('@/components/global/VideoBox.vue');

export default {
  name: 'FileModal',
  components: {
    ButtonSelect,
    Gallery,
    ImageBox,
    VideoBox,
  },
  props: {
    data: Object,
  },
  computed: {
    datepickerLocale() {
      return datepickerLocale;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.record) return false;
      if (this.isLoading) return false;
      return true;
    },
    sortedFiles() {
      return this.record.files.sort((b, a) => {
        if (a.mtime_ts !== b.mtime_ts) {
          return b.mtime_ts - a.mtime_ts;
        }
        return b.filename.localeCompare(a.filename);
      });
    },
    changes() {
      const changes = JSON.parse(JSON.stringify(this.record));
      return changes;
    },
  },
  data() {
    return {
      loadingCount: 0,
      savingCount: 0,
      uploadingCount: 0,
      record: null,
      file: null,
      ready: false,
      scheduleSaveRecord: _.debounce(() => {
        this.saveRecord();
      }, 250),
    };
  },
  methods: {
    onShow() {
      this.fetchRecord();
    },
    onClose() {
      this.saveRecord();
      this.data.showModal = false;
      this.$emit('change', this.data.record.id);
      this.$emit('close');
    },
    fetchRecord() {
      this.loadingCount++;
      this.$http
        .get(`/burntest/${this.data.record.id}`)
        .then((res) => {
          this.record = res.body.record;
          this.$nextTick().then(() => {
            this.ready = true;
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveRecord() {
      if (!this.changes) {
        return;
      }
      this.loadingCount++;
      this.savingCount++;
      this.$http
        .put(`/burntest/${this.data.record.id}`)
        .send({ changes: this.changes })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(`Failed to update record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.savingCount--;
        });
    },
    deleteRecord() {
      if (!confirm(`Do you really wish to delete '${this.data.record.sample_note}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/burntest/${this.data.record.id}`)
        .then(() => {
          this.$emit('delete', this.data.record.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    uploadFile(file) {
      this.loadingCount++;
      this.uploadingCount++;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('mtime', file.lastModified / 1000);
      this.$http
        .post(`/burntest/${this.data.record.id}/files`)
        .send(formData)
        .then((res) => {
          this.record.files.push(res.body.file);
          this.$emit('change', this.data.record.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to upload file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.uploadingCount--;
        });
    },
    deleteFile(file) {
      this.loadingCount++;
      this.$http
        .delete(`/burntest/${this.data.record.id}/files/${file.id}`)
        .then(() => {
          this.record.files = this.record.files.filter((f) => f.id !== file.id);
          this.$emit('change', this.data.record.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    downloadFiles() {
      downloadFile(`/api/burntest/${this.data.record.id}/download`);
    },
    getFirstLine(text) {
      return getFirstLine(text);
    },
  },
  watch: {
    record: {
      deep: true,
      handler() {
        if (!this.ready) {
          return;
        }
        this.scheduleSaveRecord();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-burntest {
    tr {
      border-top: 5px solid transparent;
    }

    tr > td:first-child {
      padding-right: 10px;
    }
  }
</style>
